import { IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadZip } from './downloadZipFile';
import { TFunction } from 'i18next';

export const zip_columns = (t: TFunction<'translation', undefined>): GridColDef[] => {
  return [
    { field: 'name', headerName: t('Folder Name'), flex: 1 },
    {
      field: 'actions',
      headerName: t('Actions'),
      renderCell: (params) => (
        <>
          <IconButton onClick={(e) => downloadZip(e, params.row.name)} color='primary'>
            <Tooltip title={t('export')}>
              <FileDownloadIcon />
            </Tooltip>
          </IconButton>
        </>
      )
    }
  ];
};
