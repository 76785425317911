import { Button, Box, Stack, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'context/Keycloak/Keycloak';
import { GetCompanyCloseLogos } from 'utils/Constants';
import { useSelector } from 'react-redux';
import { State } from 'store/store';

export default function SignIn() {
  const { login } = useKeycloak();
  const { t } = useTranslation<string>();
  const currentTheme = useSelector((state: State) => state.themeConfig.currentTheme);

  return (
    <>
      <div className='bodyWrapper'></div>
      <Container component='main' maxWidth='xs'>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 0 50px #ccc',
            padding: 5,
            background: '#fff'
          }}>
          <Box>
            <Stack sx={{ pb: 3 }}>
              <GetCompanyCloseLogos theme={currentTheme} width='120px' height='120px' />
            </Stack>
            <Stack>
              <Button sx={{ color: '#fff' }} color='secondary' variant='contained' onClick={() => login()}>
                {t('login')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
}
