import Button from '@mui/material/Button';

interface IProps {
  label_text: string;
  onClick: (e: any) => void;
}

export const ContainedButton = ({ label_text, onClick }: IProps) => {
  return (
    <Button variant='contained' fullWidth onClick={onClick}>
      {label_text}
    </Button>
  );
};
