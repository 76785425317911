import Server from 'entities/RCIPServerProxy';

//==============================================================
// private variables
//==============================================================

const config = {
  responseType: 'blob'
};

//==============================================================
// class ZipService
//==============================================================

export class ZipService {
  get(foldername?: string): Promise<any> {
    return Server.get(`file/zip/${foldername ? foldername : ''}`, config).then((data: any) => {
      return data;
    });
  }

  getList(): Promise<{ list: string[] }> {
    return Server.get(`file/zip/foldernames`).then((data: { list: string[] }) => {
      return data;
    });
  }
}
