import { Utils } from 'formiojs';
import { searchComponents } from './helperFunctions';

//==============================================================
// Types
//==============================================================

export interface IOperationObject {
  search_key: string;
  required_fields: string[];
  disable: boolean;
}

interface IOperationSupport {
  [operation: string]: IOperationObject;
}

//==============================================================
// private variables
//==============================================================

const operation_support: IOperationSupport = {
  GetVehicleDetails: {
    search_key: 'Vehicle',
    required_fields: [
      'RegistrationId',
      'RegistrationType',
      'CountryCd'
    ],
    disable: true
  },
  searchAddress: {
    search_key: 'Addr',
    required_fields: [
      'PostalCode',
      'StreetNumber'
    ],
    disable: true
  },
  readParty: {
    search_key: 'Party',
    required_fields: [
      'PartyNumber'
    ],
    disable: false
  }
};

const default_values: Record<string, string> = {
  RegistrationType: 'LicensePlate',
  CountryCd: 'NL'
};

const getAllComponentWhichIncludeKey = (instance: any, key: string, rowIndex: any) => {
  const condition = (instance: any) => {
    const regex = new RegExp(`\\b${key}\\b`);
    return regex.test(instance.key) && instance.rowIndex === rowIndex;
  };
  return searchComponents(instance.root, condition);
};

const getAllRequiredComponents = (instance: any, entity_details: IOperationObject, rowIndex: any) => {
  const found = new Set();
  const condition = (instance: any) => {
    return entity_details.required_fields.some((fieldName) => {
      if (
        !found.has(fieldName) &&
        new RegExp(`\\b${fieldName}\\b`).test(instance.key) &&
        instance.rowIndex === rowIndex
      ) {
        found.add(fieldName);
        return true;
      }
      return false;
    });
  };
  return searchComponents(instance.root, condition);
};

const getDeeplyNestedValue = (value: any) => {
  if (typeof value === 'object') {
    return value && Object.keys(value).reduce((result, key) => (result || {})[key], value);
  }
  return value;
};

const getValueByFieldName = (submission: any, fieldName: string) => {
  const value = getDeeplyNestedValue(Utils.getValue(submission, fieldName));
  return value ?? default_values[fieldName];
};

const getAllResponseFields = (instance: any, required_fields: any[], search_key: string, rowIndex: any) => {
  const components = getAllComponentWhichIncludeKey(instance, search_key, rowIndex);
  return components.filter((component) => required_fields.every((fieldName) => !component.key.includes(fieldName)));
};

//==============================================================
// class FieldOperationSupport
//==============================================================

export class FieldOperationSupport {
  private instance: any;
  private submission: any;
  private operation_details: IOperationObject;
  private rowIndex: any;
  private rowData: any;

  constructor(instance: any, submission: any, operation_details: IOperationObject, rowIndex: any, rowData: any) {
    this.instance = instance;
    this.submission = submission;
    this.operation_details = operation_details;
    this.rowIndex = rowIndex;
    this.rowData = rowData;
  }

  private getResponseFields() {
    return getAllResponseFields(
      this.instance.root,
      this.operation_details.required_fields,
      this.operation_details.search_key,
      this.rowIndex
    );
  }

  private getRequiredComponents() {
    return getAllRequiredComponents(this.instance, this.operation_details, this.rowIndex);
  }

  public setParametersForGetInfoCall() {
    this.operation_details.required_fields.forEach((fieldName) => {
      this.submission.data[fieldName] = getValueByFieldName(this.rowData, fieldName);
    });
  }

  public removeParametersOfGetInfoCall() {
    this.operation_details.required_fields.forEach((fieldName) => {
      delete this.submission.data[fieldName];
    });
  }

  public emptyAllNotRequiredFields() {
    const components = this.getResponseFields();
    components.forEach((component) =>
      component.defaultValue !== null ? component.setValue(component.defaultValue) : component.resetValue()
    );
  }

  public disableAllResponseFields(submission: any) {
    const components = this.getResponseFields();
    components.forEach((component) => {
      const componentValue = Utils.getValue(submission, component.key);
      component.disabled = componentValue !== null && componentValue !== '';
    });
    this.instance.root.triggerRedraw();
  }

  public clearRequiredFields = () => {
    const required_components = this.getRequiredComponents();
    required_components.forEach((component) => {
      component.resetValue();
    });
    const previous_key = `previous-${this.operation_details.search_key}-${this.rowIndex ?? 0}`;
    this.submission.data[previous_key] = '';
  };

  public validate(changedInstance: any) {
    if (changedInstance.key === this.instance.key) {
      const previous_key = `previous-${this.operation_details.search_key}-${this.rowIndex ?? 0}`;
      const required_components = this.getRequiredComponents();
      let contains_valid_value = true;
      let query_string = '';

      required_components.forEach((component) => {
        contains_valid_value =
          contains_valid_value && !component.isEmpty() && component.checkValidity(null, false, null, true);
        query_string += component.getValue();
      });

      const isCurrentValueEqualsToPreviousValue = this.submission.data[previous_key] !== query_string;
      if (contains_valid_value) {
        if (isCurrentValueEqualsToPreviousValue) {
          this.submission.data[previous_key] = query_string;
          return true;
        }
      }
    }
    return false;
  }
}

export class EntityNotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'EntityNotFoundError';
  }
}

//==============================================================
// public variables
//==============================================================

export const getOperation = (instance: any) => {
  return (instance.component.properties.operation ?? '') as string;
};

export const getOperationSupportDetails = (operation: string) => {
  return operation_support[operation];
};
