import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { Card, CardContent, Grid2 } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ZipService } from 'entities/File/ZipService';
import { useTranslation } from 'react-i18next';
import { zip_columns } from './columsDef';
import { downloadZip } from './downloadZipFile';
import { ContainedButton } from './ContainedButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width: '50%'
};

const ZipFileExtration = () => {
  const { t } = useTranslation();
  const zipService = new ZipService();
  const [open, setOpen] = useState<boolean>(false);
  const [folderList, setFolderList] = useState<string[]>([]);
  const zip_rows = () => folderList.map((folderName) => ({ id: folderName, name: folderName }));

  const getFolderNames = async () => {
    const zipListRes = await zipService.getList().catch(() => setFolderList([]));
    if (zipListRes && Array.isArray(zipListRes.list)) {
      setFolderList(zipListRes.list);
    }
  };

  const handleOpen = () => {
    getFolderNames();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <ContainedButton label_text={t('Download zip of files')} onClick={handleOpen} />
      <Modal open={open} onClose={handleClose}>
        <Card sx={style}>
          <CardContent>
            <Grid2 container spacing={2}>
              <Typography variant='h5'>{t('Zip Extractor')}</Typography>
              <ContainedButton label_text={t('Download All')} onClick={downloadZip} />
              <DataGridPro rows={zip_rows()} columns={zip_columns(t)} getRowId={(row) => row.name} />
            </Grid2>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

export default ZipFileExtration;
