import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import FormRenderer from 'component/molecules/Formrenderer/FormRenderer';
import { useKeycloak } from 'context/Keycloak/Keycloak';
import { SnackBarProvider } from 'context/Snackbar/Snackbar';
import Layout from 'component/pages/Layout/Layout';
import { useTranslation } from 'react-i18next';
import Login from 'component/pages/Login/Login';
import { LoaderProvider } from 'context/Loader/Loader';
import { Action } from 'component/pages/Action/Action';
import config from 'config/config';
import PartyDetails from 'component/pages/Party/PartyDetails';
import Underconstruction from 'component/pages/UnderConstruction/Underconstruction';
import SettingsPage from 'component/pages/Settings/SettingsPage';
import FormBuilderRenderer from 'component/molecules/Formrenderer/FormBuilderRenderer';
import InsuranceOverview from 'component/pages/InsuranceOverview/InsuranceOverview';
import useThemeConfig from 'component/pages/Settings/useThemeConfig';
import { CssBaseline } from '@mui/material';
import ErrorBoundary from 'component/pages/ErrorBoundary/ErrorBoundary';
import NetworkErrorDetector from 'component/pages/ErrorBoundary/NetworkErrorDetector';

const App = () => {
  const { initialized, authenticated } = useKeycloak();
  const { i18n } = useTranslation<string>();
  const theme = useThemeConfig();

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <CssBaseline />
        <NetworkErrorDetector>
          <Router basename={config.basename}>
            {initialized() && !authenticated() && <Login />}
            {authenticated() && (
              <LoaderProvider>
                <SnackBarProvider>
                  <Layout>
                    <ErrorBoundary>
                      <Routes>
                        <Route path='/' element={<Outlet />}>
                          {/* Nested Routes under '/' */}
                          <Route index element={<Action />} />
                          <Route path='action' element={<Action />} />
                          <Route path='insuroverview' element={<InsuranceOverview />} />

                          {/* Underconstruction Routes */}
                          <Route path='insurances' element={<Underconstruction />} />
                          <Route path='offer' element={<Underconstruction />} />
                          <Route path='offers' element={<Underconstruction />} />
                          <Route path='tasks' element={<Underconstruction />} />
                          <Route path='claim' element={<Underconstruction />} />

                          {/* Settings Routes */}
                          <Route path='settings' element={<SettingsPage />} />
                          <Route path='settings/:key' element={<SettingsPage />} />

                          {/* Forms */}
                          <Route path='forms' element={<FormBuilderRenderer />} />

                          {/* Party Details */}
                          <Route path='party/partydetails/:partyNumber/:module' element={<PartyDetails />} />

                          {/* Form Renderer */}
                          <Route path='formrenderer' element={<Outlet />}>
                            <Route index element={<InsuranceOverview />} />
                            <Route path='insuroverview' element={<InsuranceOverview />} />
                            <Route path='insuroverview/:partynumber' element={<InsuranceOverview />} />
                            <Route path=':formsrc/lobcd/:type' element={<FormRenderer />} />
                            <Route path=':formsrc/lobcd/:type/:partynumber' element={<FormRenderer />} />
                            <Route path=':formsrc/lobcd/:type/:partynumbe/:policyparamsr' element={<FormRenderer />} />
                            <Route path=':formsrc/:partynumber/:policyparams' element={<FormRenderer />} />
                            <Route path=':formsrc/:partynumber' element={<FormRenderer />} />
                            <Route path=':formsrc' element={<FormRenderer />} />
                          </Route>
                        </Route>
                      </Routes>
                    </ErrorBoundary>
                  </Layout>
                </SnackBarProvider>
              </LoaderProvider>
            )}
          </Router>
        </NetworkErrorDetector>
      </I18nextProvider>
    </ThemeProvider>
  );
};

export default App;
