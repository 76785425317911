import { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { FileService } from 'entities/File/FileService';
import {
  Box,
  Button,
  Container,
  Grid2,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  CardMedia
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import ZipFileExtration from './ZipFileExtraction/ZipFileExtration';

function FileUploadPage(): JSX.Element {
  const { t } = useTranslation();
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const fileService = new FileService();

  const refreshFileList = async () => {
    try {
      const updatedList = await fileService.getList();
      if (updatedList && Array.isArray(updatedList.list)) {
        setFileList(updatedList.list);
      }
    } catch (error) {
      console.error('Error fetching updated file list:', error);
      setFileList([]);
    }
  };

  useEffect(() => {
    refreshFileList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileUpload = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    try {
      const response = await fileService.save(formData);
      alert(response.message);
      await refreshFileList();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(event.target.files);
    }
  };

  const handleReadFile = async (filename: any) => {
    if (filename) {
      const response = await fileService.get(filename);
      const url = URL.createObjectURL(response);
      setFilePreview(url);
    } else {
      alert('Please enter a filename');
    }
  };

  const handleDeleteFile = async (filename: any) => {
    if (filename) {
      try {
        const response = await fileService.delete(filename);
        alert(response.message);
        await refreshFileList();
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    } else {
      alert('Please enter a filename');
    }
  };

  const handleDownloadFile = async (filename: any) => {
    const getfile = await fileService.get(filename);
    const downloadUrl = URL.createObjectURL(getfile);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'File Name', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      renderCell: (params) => (
        <>
          <Button variant='outlined' color='primary' onClick={() => handleReadFile(params.row.name)}>
            Preview
          </Button>
          <Button
            variant='outlined'
            color='error'
            onClick={() => handleDeleteFile(params.row.name)}
            style={{ marginLeft: '10px' }}>
            Delete
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => handleDownloadFile(params.row.name)}
            style={{ marginLeft: '10px' }}>
            Download
          </Button>
        </>
      )
    }
  ];

  const rows = fileList.map((file) => ({
    id: file, // Using the file name as the unique id
    name: file
  }));

  return (
    <Container maxWidth='md' sx={{ mt: 4 }}>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Grid2 size={12}>
            <ZipFileExtration />
          </Grid2>
        </CardContent>
      </Card>
      {/* File Upload Section */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant='h5' gutterBottom>
            {t('Upload Files')}
          </Typography>
          <form onSubmit={handleFileUpload} encType='multipart/form-data'>
            <Grid2 container spacing={2}>
              <Grid2 size={12}>
                <Button variant='contained' component='label' fullWidth>
                  {t('Select')}
                  <input type='file' name='files' id='files' multiple hidden required onChange={handleFileSelect} />
                </Button>
              </Grid2>
              {selectedFiles && (
                <Grid2 size={12}>
                  <Typography variant='h6'>Selected Files:</Typography>
                  <List>
                    {Array.from(selectedFiles).map((file) => (
                      <ListItem key={file.name}>
                        <ListItemText primary={file.name} />
                      </ListItem>
                    ))}
                  </List>
                </Grid2>
              )}
              <Grid2 size={12}>
                <Button type='submit' variant='contained' color='primary' fullWidth>
                  {t('Upload')}
                </Button>
              </Grid2>
            </Grid2>
          </form>
        </CardContent>
      </Card>

      {/* Read File Section */}
      {filePreview && (
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Box sx={{ mt: 4 }}>
              <Typography variant='h6'>{t('File Preview')}</Typography>
              <CardMedia component='img' image={filePreview} alt='Preview' sx={{ width: '200px', mt: 2 }} />
              <Button href={filePreview} download variant='outlined' sx={{ mt: 2 }}>
                {t('Download')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}

      {/* DataGridPro for File List */}
      <Card>
        <CardContent>
          <Typography variant='h5' gutterBottom>
            File List
          </Typography>
          <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
              rows={rows}
              columns={columns}
              getRowId={(row) => row.name} // Specify that the id is the file name
            />
          </div>
        </CardContent>
      </Card>
    </Container>
  );
}

export default FileUploadPage;
