export const isJsonValue = (item) => {
    return typeof item !== 'object';
};
export const isJsonObject = (item) => {
    return item !== null && typeof item === 'object' && !Array.isArray(item);
};
export const isJsonArray = (item) => {
    return typeof item === 'object' && Array.isArray(item);
};
export const isJsonValuedArray = (item) => {
    return typeof item === 'object' && Array.isArray(item) && item.every((value) => typeof value !== 'object');
};
export const logJson = (object) => {
    const json = JSON.stringify(object, null, 2);
    console.log(`${json}\n`);
};
