import { ZipService } from 'entities/File/ZipService';

const zip_file_name = 'ep-files.zip';

export const downloadZip = async (e: React.MouseEvent<any, MouseEvent>, foldername?: string) => {
  e.preventDefault();
  const zipService = new ZipService();
  const zip_file = await zipService.get(foldername ?? '');
  const downloadUrl = URL.createObjectURL(zip_file);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = zip_file_name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
