// import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {
  FormControl,
  InputLabel,
  Select,
  Box,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { menuOptions, saveButtonWrapper, selectOutline, setBackgroundColor } from './FormSaveButtonStyles';
import SplitButton from 'component/atoms/ButtonGroup/SplitButton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormFields from './FormFields';
import { IForm } from '@ccs-dip/common/types/formio-types';

export interface IFormBuilderSave {
  handleSaveMenuClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSaveClose: (menuValue: string) => void;
  handleFormSubmit: (current: IForm, savedOption?: string) => void;
  openSaveoption: boolean;
  menuValue: string;
  saveanchorEl: null | HTMLElement;
  currentForm: IForm;
  isEditing: boolean;
}

const initializeSaveAsForm = (formJSON: IForm): IForm => {
  const saveAsForm = { ...formJSON };
  saveAsForm.title = '';
  saveAsForm.name = '';
  saveAsForm.key = '';
  saveAsForm.path = '';
  saveAsForm._id = '';
  saveAsForm.created = '';
  saveAsForm.modified = '';
  saveAsForm.savedOption = 'Draft';
  saveAsForm.productId = '';
  return saveAsForm;
};

export default function FormSaveButton({
  handleFormSubmit,
  handleSaveClose,
  menuValue,
  openSaveoption,
  currentForm
}: IFormBuilderSave) {
  const { t } = useTranslation();
  const [saveAsForm, setSaveAsForm] = useState<IForm>(initializeSaveAsForm(currentForm));
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = (selectedOption: string): void => {
    switch (selectedOption) {
      case 'save':
        handleFormSubmit(currentForm, menuValue);
        break;
      case 'saveas':
        handleClickOpen();
        break;
      default:
      // handle invalid action
    }
  };
  useEffect(() => {
    setSaveAsForm(initializeSaveAsForm(currentForm));
  }, [currentForm]);

  const handleDialogSubmit = () => {
    handleFormSubmit(saveAsForm);
    handleSaveClose('Draft');
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id='Version'>Version</InputLabel>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Select
            labelId='Version'
            id='Version'
            value={menuValue}
            label='Version'
            size='small'
            sx={selectOutline}
            onChange={(e) => {
              handleSaveClose(e.target.value);
            }}>
            {menuOptions.map((itm: string, index: number) => {
              return (
                <MenuItem key={index} value={setBackgroundColor(itm).text} disableRipple>
                  {setBackgroundColor(itm).icon}
                  {setBackgroundColor(itm).text}
                </MenuItem>
              );
            })}
          </Select>

          <SplitButton
            options={['save', 'saveas']}
            handleClick={handleClick}
            disabledOptions={currentForm.id ? [] : ['saveas']}
          />
        </Box>
        <Dialog fullWidth maxWidth='lg' open={open} onClose={handleClose}>
          <DialogTitle> {t('saveas')}</DialogTitle>
          <DialogContent>
            <FormFields
              currentForm={saveAsForm}
              setCurrentForm={setSaveAsForm}
              isEditingForm={false}
              isWizardEditing={false}
              isPreviewMode={false}
            />
          </DialogContent>
          <DialogActions>
            <Button
              id='demo-customized-button'
              aria-controls={openSaveoption ? 'demo-customized-menu' : undefined}
              aria-haspopup='true'
              size='small'
              aria-expanded={openSaveoption ? 'true' : undefined}
              variant='contained'
              sx={saveButtonWrapper()}
              disableElevation
              onClick={handleDialogSubmit}>
              {t('save')}
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </FormControl>
    </div>
  );
}
