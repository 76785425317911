import { Formio, Utils } from 'formiojs';
import { IFormioSubmissionRequest, IFormioSubmissionResponse } from '@ccs-dip/common/types/formio-types';
import { tryInitializeFieldSelectElement } from './tryInitializeFieldSelectElement';
import config from 'config/config';
import { attachEventHandler, formioEventHandlerType } from './attachEventHandlerToFormioInstance';
import { replaceFormChangeEventHandler } from './CustomPropertySupport/replaceFormFuctions';
import { DynamicContainer } from './CustomPropertySupport/DynamicContainer';
import { summaryRenderEventHandler } from './CustomPropertySupport/summaryComponentFunctions';
import { loaderService } from 'entities/Loader/LoaderService';
import {
  FieldOperationSupport,
  getOperation,
  getOperationSupportDetails,
  EntityNotFoundError,
  IOperationObject
} from './CustomPropertySupport/getInfoByOperationSupport';
import { acceptanceChangeEventHandler } from './acceptanceQuestionAnswerMapper';
import { getCustomProperty, getValueBySubPath } from './CustomPropertySupport/helperFunctions';
import { coverageChangeEventHandler } from './coverageMapper';
import { CustomValidationSupport } from './CustomValidationSupport';

//==============================================================
// extend global interfaces
//==============================================================

declare global {
  interface Window {
    formio_support: FormioRuntimeSupport;
  }
}

//==============================================================
// Types
//==============================================================

type CustomEvent = 'success' | 'error' | 'notfound';

//==============================================================
// private variables
//==============================================================

const getDataToSendServer = (form: any, submission: any, component: any, gridData?: any) => {
  const transactionId = getTransactionId(form);
  const key = component.key ?? '';
  const tags = component.tags ?? [];
  const properties = component.properties ?? {};

  submission.transactionId = transactionId;

  return {
    transactionId,
    form: {
      name: form._form.name
    },
    component: {
      key,
      tags,
      properties
    },
    submission,
    gridData
  };
};

const getTransactionId = (instance: any): string => {
  const root = getRootInstance(instance);
  return root.submission.transactionId as string;
};

const getRootInstance = (instance: any): any => {
  const root = instance.root;

  if (root && instance.id === root.id) {
    return instance;
  }

  return getRootInstance(root);
};

const send_to_server = (data: IFormioSubmissionRequest): Promise<IFormioSubmissionResponse> => {
  const url = new URL('/api/formio-submission', config.server_url).href;

  return Formio.makeStaticRequest(url, 'POST', data).catch(() => {
    // swallow error, it will be displayed by the ErrorFetchPlugin
    if (data.component.properties.operation.includes('read')) {
      throw new EntityNotFoundError();
    }
  });
};

const emitCustomEvent = (form: any, component: any, type: CustomEvent) => {
  const instance = getRootInstance(form);
  instance.emit('customEvent', {
    type,
    component,
    instance: form
  });
};

const serviceOperation = async (form: any, data: IFormioSubmissionRequest) => {
  try {
    let response_submission = undefined;
    await loaderService.loader(
      send_to_server(data).then((response: IFormioSubmissionResponse) => {
        const messages = response?.messages ?? [];
        messages.forEach((message) => {
          const component = Utils.getComponent(form.components, message.ref, false);
          component.setCustomValidity(
            {
              level: 'error',
              message: message.value
            },
            true,
            true
          );
        });
        response_submission = response?.submission;
        if (response_submission !== undefined) {
          form.setSubmission(response_submission);
          emitCustomEvent(form, data.component, 'success');
        }
      })
    );
    return response_submission;
  } catch (error: any) {
    if (error instanceof EntityNotFoundError) {
      emitCustomEvent(form, data.component, 'notfound');
      throw error;
    }
  }
};

//==============================================================
// Formio Event-Handler
//==============================================================

const populateFieldsEventHandler = (
  changedInstance: any,
  instance: any,
  submission: any,
  operation_details: IOperationObject
) => {
  const previous = submission.data[`previous-${operation_details.search_key}-${instance.rowIndex ?? 0}`];
  const rowData =
    instance.rowIndex !== undefined ? { data: submission.data[instance.parent.key][instance.rowIndex] } : submission;
  let gridData = undefined;

  // build submission object for editgrid as it not does until the row is saved
  if (instance.parent.type === 'editgrid' && instance.parent.editRows[instance.rowIndex]?.data) {
    const result: any[] = [];
    instance.parent.editRows.forEach((row: any) => {
      result.push(row.data);
    });
    submission.data[instance.parent.key] = result;
    gridData = {
      index: instance.rowIndex,
      key: instance.parent.key
    };
  }

  const fieldOperationSupport = new FieldOperationSupport(
    instance,
    submission,
    operation_details,
    instance.rowIndex,
    rowData
  );
  fieldOperationSupport.removeParametersOfGetInfoCall();

  if (fieldOperationSupport.validate(changedInstance)) {
    if (previous) {
      // empty only if that operation has been performed once
      fieldOperationSupport.emptyAllNotRequiredFields();
    }
    fieldOperationSupport.setParametersForGetInfoCall();
    const data = getDataToSendServer(instance.root, submission, instance.component, gridData);
    serviceOperation(instance.root, data)
      .then((response_submission) => {
        if (response_submission && operation_details.disable) {
          fieldOperationSupport.disableAllResponseFields(response_submission);
        }
      })
      .catch(() => {
        fieldOperationSupport.clearRequiredFields();
      });
  }
};

const populateFieldsChangeEventHandler: formioEventHandlerType = (eventData, instance, _emitEventName) => {
  const operation_details = getOperationSupportDetails(getOperation(instance));
  if (operation_details) {
    const changedInstance = eventData.changed?.instance;
    if (!changedInstance) {
      instance.triggerChange();
      return;
    }

    const submission = { data: eventData.data };
    populateFieldsEventHandler(changedInstance, instance, submission, operation_details);
  } else if (eventData.changed?.instance.key === instance.key) {
    const submission = { data: eventData.data };
    if (eventData.changed.value !== submission.data[`prev-${instance.key}`]) {
      submission.data[`prev-${instance.key}`] = eventData.changed.value;
      const data = getDataToSendServer(instance.root, submission, instance.component);
      serviceOperation(instance.root, data);
    }
  }
};

const populateFieldsBlurEventHandler: formioEventHandlerType = (changedInstance, instance, _emitEventName) => {
  const operation_details = getOperationSupportDetails(getOperation(instance));
  if (operation_details) {
    populateFieldsEventHandler(changedInstance, instance, instance.root.submission, operation_details);
  }
};

//==============================================================
// class FormioRuntimeSupport
//==============================================================

export class FormioRuntimeSupport {
  validator = new CustomValidationSupport();
  dc_support = new DynamicContainer();

  send(form: any, submission: any, component: any) {
    if (!form.checkValidity(null, true, null, true)) {
      emitCustomEvent(form, component, 'error');
      return;
    }
    const data = getDataToSendServer(form, submission, component);
    serviceOperation(form, data).catch(() => {});
  }

  wizardNextButtonAction(form: any, submission: any, component: any) {
    const isValid: boolean = form.currentPage.checkValidity(null, true, null, true);
    if (isValid) {
      const operation = getCustomProperty(component, 'operation');
      if (operation !== undefined && operation !== '') {
        const data = getDataToSendServer(form, submission, component);
        serviceOperation(form, data).catch(() => {});
      }
      form.setPage(form.currentNextPage);
    } else {
      emitCustomEvent(form, component, 'error');
    }
  }

  wizardPreviousButtonAction(form: any) {
    form.prevPage();
  }

  apiFieldCustomDefaultValue() {
    tryInitializeFieldSelectElement();
  }

  replaceFormCustomDefaultValue(instance: any, submission: any) {
    attachEventHandler(instance, 'change', '', replaceFormChangeEventHandler);
    const value = getValueBySubPath(submission, instance.key);
    return value ? value : instance.getValue();
  }

  // To render the summary of previous pages/screens
  wizardSummaryCustomDefaultValue(instance: any, submission: any) {
    attachEventHandler(instance, 'render', '', summaryRenderEventHandler);
    const value = getValueBySubPath(submission, instance.key);
    return value ? value : {};
  }

  populateFieldsWithGetInfoService(instance: any, event: any) {
    const handler = event === 'blur' ? populateFieldsBlurEventHandler : populateFieldsChangeEventHandler;
    attachEventHandler(instance, event, '', handler);
    return instance.dataValue ? instance.dataValue : instance.component.defaultValue;
  }

  acceptanceQuestionAnswerValueMapper(instance: any) {
    attachEventHandler(instance, 'change', '', acceptanceChangeEventHandler);
  }

  coverageValueMapper(instance: any) {
    attachEventHandler(instance, 'change', '', coverageChangeEventHandler);
  }
}
