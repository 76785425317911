import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';

export const drawerWidth = 240;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 2),
  ...theme.mixins.toolbar
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '.MuiPaper-root.MuiPaper-elevation': {
    backgroundColor: theme.palette.background.paper
  },
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export const miniDrawerStyle = {
  display: 'flex',
  textDecoration: 'none',
  flexDirection: 'column',
  color: (theme: any) => theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'none',
    color: (theme: any) => theme.palette.secondary.main
  },
  '& .ListItemStyle': {
    display: 'block',
    padding: 'none'
  },
  '& .ListItemButtonStyle': {
    textDecoration: 'none',
    minHeight: '48px',
    justifyContent: 'center',
    '&.Mui-selected': {
      'svg path[stroke]': {
        stroke: (theme: any) => theme.palette.secondary.main
      },
      'svg path[fill="#00005a"]': {
        fill: (theme: any) => theme.palette.secondary.main
      },
      '& .MuiListItemText-primary': {
        color: (theme: any) => theme.palette.secondary.main
      }
    }
  },
  '& .ListItemButton': {
    textDecoration: 'none',
    minHeight: '48px',
    justifyContent: 'center',
    '&.Mui-selected': {
      'svg path[fill="#00005a"]': {
        fill: (theme: any) => theme.palette.secondary.main
      },
      '& .MuiListItemText-primary': {
        color: (theme: any) => theme.palette.secondary.main
      }
    }
  },
  '& .MuiListItem-root': {
    'a:hover': {
      textDecoration: 'none'
    }
  }
};

export const itemBox = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: (theme: any) => theme.spacing(1),
  '&.MuiBox-root': {
    alignItems: 'flex-start',
    py: 0.5,
    px: 1.5
  }
};

export const iconStyle2 = {
  ml: 0
};

export const activeLink = {
  background: (theme: Theme) => theme.palette.background.paper,
  width: '40px',
  height: '40px',
  borderRadius: '50%'
};

// Additional styles for icons, typography, and tooltips
export const accountIconStyles = {
  fontSize: '22px',
  color: (theme: any) => theme.palette.secondary.main
};

export const avatarStyles = {
  color: (theme: any) => theme.palette.primary.contrastText,
  bgcolor: (theme: any) => theme.palette.secondary.main,
  textTransform: 'uppercase',
  fontSize: 14
};

export const iconButtonStyles = {
  width: '50px',
  height: '50px',
  '&.MuiButtonBase-root': {
    marginTop: '10px'
  }
};

export const emailLinkStyles = {
  textDecoration: 'none',
  color: 'inherit',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  verticalAlign: 'middle'
};

export const emailTextStyles = {
  fontSize: 14,
  mb: 1,
  fontWeight: 'bold',
  color: (theme: any) => theme.palette.text.primary,
  width: '150px',
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};
